import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ActionButton from './ActionButton';
import React, { Fragment } from 'react';
import { makeStyles } from '@mui/styles';
import { useRoundware } from '../../hooks';
import Container from '@mui/material/Container';

import banner from '../../assets/rw-icon-cluster.png';
import { GeoListenMode } from 'roundware-web-framework';
import useStyles from './styles';
import config from 'config.json';

export const LandingPage = () => {
	const { roundware, forceUpdate } = useRoundware();
	const classes = useStyles();

	const project = roundware.project;
	if (!project || project.projectName === '(unknown)') {
		return null;
	}

	return (
		<Grid container className={classes.landingHeader}>
			<Grid container justifyContent='left' className={classes.landingContent}>
				<Grid container style={{  }}>
					{/*<Grid item style={{ margin: 'auto', height: '15vh', textAlign: 'center', paddingTop: 15 }} sm={12}>
						{/*<Typography
	            variant={"h2"}
	            className={classes.landingTitle}
	          >
	            {roundware.project && roundware.project.projectName}
	          </Typography>
						<img src={banner} className={classes.landingBanner} />

					</Grid>*/}
					<Grid item sm={12}>
						<Typography
							className={classes.landingHeading1}
							style={{ textAlign: 'left', marginTop: 100 }}
						>
							Welcome to:
						</Typography>
						<Typography
	            variant={"h2"}
	            className={classes.landingTitle}
	          >
	            BrainPain.me!
	          </Typography>
					</Grid>
					<Grid item sm={12}>
						<Typography
							variant={'subtitle1'}
							className={classes.landingTagline}
							style={{ textAlign: 'left', fontSize: '1.3rem', marginBottom: 30 }}
						>
							The purpose of this site is to help people learn about and
							recover from TMS, a widespread pain condition that afflicts
							many of us in the modern world. Research on TMS was pioneered
							by the late Dr. John E. Sarno, M.D.
						</Typography>
						<Typography
	            className={classes.landingHeading2}
							style={{ textAlign: 'left' }}
	          >
	            This site has two functions:
	          </Typography>
					</Grid>
				</Grid>
				<Grid
					container
					direction="row"
					className={classes.landingButtons}
					style={{  }}
				>
					{project.data?.listen_enabled && (
						<Grid
							container
							direction="column"
							sm={6}
							xs={12}
							style={{  }}
						>
							<Grid item>
								<ActionButton
									onClick={() => {
										if (!config.AUTOPLAY) return;
										if (!roundware.mixer || !roundware.mixer?.playlist) {
											roundware?.activateMixer({ geoListenMode: GeoListenMode.MANUAL }).then(() => {
												if (roundware && roundware.uiConfig && roundware.uiConfig.listen && roundware.uiConfig.listen[0]) {
													const listen_tags = roundware.uiConfig.listen[0].display_items.map((i) => i.tag_id);
													roundware.mixer.updateParams({
														listenerLocation: roundware.listenerLocation,
														minDist: 0,
														maxDist: 0,
														recordingRadius: 0,
														listenTagIds: listen_tags,
													});
													roundware.mixer.play();
													forceUpdate();
												}
											});
										} else {
											roundware.mixer.play();
											forceUpdate();
										}
									}}
									label={'Listen'}
									linkTo={'/listen'}
									style={{ width: '100%' }}
									styleButton={{ backgroundColor: '#98CE00' }}
								/>
							</Grid>
							<Grid item>
								<Typography
									variant={'subtitle1'}
									className={classes.landingCopy}
									style={{ textAlign: 'left', width: '95%' }}
								>
									Hear a stream of personal stories and research comments
									mixed with an ambient soundtrack by browsing a map interface.
								</Typography>
							</Grid>
						</Grid>
					)}

					{project.data?.speak_enabled && (
						<Grid
							container
							direction="column"
							sm={6}
							xs={12}
							justifyContent='left'
							style={{  }}
						>
							<Grid item>
								<ActionButton
									label={'Speak'}
									linkTo={'/speak'}
									styleButton={{ backgroundColor: '#6CCFF6' }}
								/>
							</Grid>
							<Grid item>
								<Typography
									variant={'subtitle1'}
									className={classes.landingCopy}
									style={{ textAlign: 'left', width: '95%' }}
								>
									Record and upload your own story or comment and share it on
									the map with everyone else via the LISTEN feature.
								</Typography>
							</Grid>
						</Grid>
					)}
				</Grid>
				<Divider />
				<Grid container style={{  }}>
					<Typography
						className={classes.landingHeading2}
						style={{ textAlign: 'left' }}
					>
						About the project:
					</Typography>
					<Grid item sm={12}>
						<Typography
							className={classes.landingCopy}
							style={{ textAlign: 'left' }}
						>
							My name is Halsey and I have suffered from TMS on and off for
							most of my adult life, so in some ways, this site is part of
							my own personal healing process. This said, I hope that others
							will find it useful and will join in. Let’s try to help each
							other!
						</Typography>
						<Typography
							className={classes.landingCopy}
							style={{ textAlign: 'left' }}
						>
							Please note that I am an artist, not a medical professional,
							so understand that this site is not any kind of official
							therapy or treatment, but rather an artistic intervention.
							It aspires to be a place where TMS sufferers and their loved
							ones can asynchronously commiserate, connect and work towards
							healing themselves.
						</Typography>
						<Typography
							className={classes.landingCopy}
							style={{ textAlign: 'left', marginBottom: 100 }}
						>
							Please contact me with feedback and ideas. I want this site
							to be as useful to as many people as possible, so the more
							diverse inputs I receive, the more effective it can become.
							This is an experiment.
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
